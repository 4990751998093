import request from './request';

export default {
  async getBoxStickers(id) {
    let url = `/api/orders/${id}/attachments/get_box_stickers/`;
    let ret = await request.request(url, 'get', {}, {});
    return ret.data;
  },

  async createBoxStickers(id) {
    let url = `/api/orders/${id}/attachments/create_box_stickers/`;
    let ret = await request.request(url, 'post', {}, {});

    if (ret['status'] !== 200) {
      console.log('Error while creating box stickers; ret=', ret);
      throw 'Error while creating box stickers; status_code=' + ret['status'];
    }

    return ret.data;
  }
};
