<template>
  <v-card :class="operatorClass()">
    <v-card-title>{{ $t('parcels.parcels') }}</v-card-title>

    <v-data-table
      dense
      :loading="loading"
      :loading-text="$t('loading')"
      :headers="headers"
      :items="packagerData"
      :footer-props="{
        'items-per-page-text': $t('table.per-page')
      }"
      :no-data-text="$t('table.no-data')"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      :options.sync="options"
      :single-expand="true"
      :expanded.sync="expanded"
      show-expand
      @click:row="expandRow"
    >
      <template #top>
        <v-row align-self="end">
          <v-col class="text-right">
            <v-btn color="primary" dark class="mb-2 mr-2" @click="onNewItem">
              {{ $t('new') }}
              <v-icon right dark> mdi-plus </v-icon>
            </v-btn>
          </v-col>
        </v-row>

        <v-dialog v-model="editDialog" max-width="700px">
          <packager-form
            :edited-item="editedItem"
            :packages="packages"
            :order="order"
            prefix="dialog"
            @cancel="onCancel"
          />
        </v-dialog>
      </template>

      <template #[`item.boxType`]="{ item }">
        {{ getBoxName(item.boxType) }}
      </template>

      <template #[`item.actions`]="{ item }">
        <v-icon small class="mr-2" @click="editItem(item)"> mdi-pencil </v-icon>

        <v-icon small @click="deleteItem(item)"> mdi-delete </v-icon>
      </template>
      <template #expanded-item="{ headers, item }">
        <td :colspan="headers.length">
          <parcel-set :parcel-set="item" />
        </td>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import PackageDefinitionService from '@/services/PackageDefinitionService.js';
import WarehouseService from '@/services/WarehouseService.js';
import BoxStickersService from '@/services/BoxStickersService.js';
import PackagerForm from '@/views/operator/components/PackagerForm';
import ParcelSet from '@/views/dashboard/components/warehouse/parcelSet.vue';

export default {
  components: {
    PackagerForm,
    ParcelSet
  },
  props: {
    order: {
      type: Object,
      required: true
    }
  },

  data: function () {
    return {
      options: {},
      packages: [],
      packagerData: [],
      location: '',

      editDialog: false,
      editedIndex: -1,
      editedItem: null,
      loading: false,
      sortBy: 'limit',
      sortDesc: false,

      expanded: []
    };
  },

  computed: {
    headers() {
      return [
        {
          text: this.$t('warehouse.item.pcs'),
          value: 'pcs',
          sortable: true,
          align: 'right'
        },
        {
          text: this.$t('panel.num-of-boxes'),
          value: 'boxes',
          sortable: false,
          align: 'right'
        },
        {
          text: this.$t('panel.total'),
          value: 'total',
          sortable: false,
          align: 'right'
        },
        {
          text: this.$t('parcel.box-type'),
          value: 'boxType',
          sortable: false,
          align: 'right'
        },
        {
          text: this.$t('warehouse.location'),
          value: 'location',
          sortable: false,
          align: 'right'
        },
        {
          text: this.$t('actions'),
          value: 'actions',
          sortable: false
        }
      ];
    }
  },

  watch: {
    packagerData: {
      handler(newValue) {
        newValue.forEach((item) => {
          item.total = item.pcs * item.boxes;
          item.isValid = item.boxType && item.total > 0;
        });
      },
      deep: true
    }
  },
  activated() {
    this.machine?.id && this.load();
  },

  async mounted() {
    await this.load();
    this.$root.$on('operator:endOfProduction', this.shipPackages);
    this.$root.$on('parcel:added', this.onAdded);
    this.$root.$on('parcel:updated', this.onUpdated);
  },
  beforeDestroy() {
    this.$root.$off('operator:endOfProduction', this.shipPackages);
    this.$root.$off('parcel:added', this.onAdded);
    this.$root.$off('parcel:updated', this.onUpdated);
  },

  methods: {
    async load() {
      try {
        this.loading = true;
        [this.packages] = await Promise.all([
          PackageDefinitionService.getPackageDefinition(),
          this.loadParcelSets()
        ]);
        console.log(this.packages);
      } catch (err) {
        this.showError(this, err);

        this.$emit('error', `Can't read waste ranges: ${err}`);
      } finally {
        this.loading = false;
      }
    },

    async updateStickers() {
      console.log('recreating box stickers');

      try {
        await BoxStickersService.createBoxStickers(this.order.id);
      } catch (err) {
        console.log('error:', err);
        const msg = `${this.$t(
          'parcels.cant-create-box-stickers-document'
        )}: ${err}`;
        this.showError(this, msg);
      }
    },

    async deleteItem(item) {
      console.log('delete parcel:', item);
      const res = await this.$swal({
        html: this.$t('parcels.are-you-sure-you-want-to-delete-this-entry'),
        showCancelButton: true,
        confirmButtonText: this.$t('yes'),
        cancelButtonText: this.$t('no')
      });

      if (res.isDismissed) {
        return;
      }

      const idx = this.packagerData.indexOf(item);

      try {
        let result = await WarehouseService.delete(item);
        console.log('result: ', result);
        this.packagerData.splice(idx, 1);
        this.updateStickers();
      } catch (err) {
        this.showError(this, err);
      }
    },

    shipPackages() {
      console.log('shipping');
      this.packagerData.forEach((i) => {
        i['status'] = 'sent';
        console.log('ship', i);
      });
    },

    onNewItem() {
      this.editedItem = null;
      this.editDialog = true;
    },

    editItem(item) {
      this.editedIndex = this.packagerData.indexOf(item);
      this.editedItem = this.packagerData[this.editedIndex];
      this.editDialog = true;
    },

    onCancel() {
      this.editDialog = false;
      this.$nextTick(() => {
        this.editedItem = null;
        this.editedIndex = -1;
      });
    },

    async loadParcelSets() {
      let set = [];
      let whItems = await WarehouseService.getItems(
        {
          ...this.options,
          filters: [
            ...(this.options.filters || []),
            {
              field: 'order',
              operation: '=',
              value: this.order.order_no
            }
          ]
        },
        null
      );
      whItems[0].forEach((item) => {
        let r = {
          order: this.order.order_no,
          total: item.pcs * item.boxes,
          pcs: item.pcs,
          boxes: item.boxes,
          boxType: item.boxType,
          location: item.location,
          id: item.id,
          status: item.status
        };
        set.push(r);
      });
      this.packagerData = set;
    },

    getBoxName(id) {
      let ret = '';
      this.packages.forEach((i) => {
        if (id == i.id) {
          ret = i.description;
        }
      });
      return ret;
    },

    async onAdded(ps) {
      console.log('parcels.onAdded:', ps);
      this.editDialog = false;
      try {
        this.packagerData.push(ps);
        this.updateStickers();
      } catch (err) {
        this.showError(this, err);
      }
    },

    async onUpdated(ps) {
      console.log('parcels.onUpdated:', ps);
      this.editDialog = false;
      this.editedIndex = -1;
      try {
        await this.loadParcelSets();
        await this.updateStickers();
      } catch (err) {
        this.showError(this, err);
      }
    },

    expandRow(item) {
      this.expanded = item === this.expanded[0] ? [] : [item];
    }
  }
};
</script>

<style></style>
