<template>
  <v-dialog v-model="showDialog" max-width="500px" @keydown.esc.stop="close">
    <v-card>
      <v-alert v-if="error" type="error" dense text>
        {{ error }}
      </v-alert>
      <v-card-title>
        <span class="headline">{{ $t('edit') }}</span>
      </v-card-title>
      <v-card-text>
        <validation-observer ref="netgross">
          <v-row class="pb-2">
            <v-col cols="6">
              <validation-provider
                v-slot="{ errors }"
                :rules="{ required: true, numeric: true, min_value: 0 }"
                :name="$t('net')"
              >
                <v-text-field
                  v-model="item.quantity_net"
                  :label="$t('net')"
                  type="number"
                  :error-messages="errors"
                  @input="clearError"
                  @focus="keyboardField = 'net'"
                ></v-text-field>
              </validation-provider>
            </v-col>
            <v-col v-if="keyboardField == 'net'" cols="6" sm="6" md="4">
              <SimpleKeyboard
                :input="item.quantity_net"
                :layout="numeric"
                @onChange="
                  (input) => {
                    item.quantity_net = input;
                  }
                "
              />
            </v-col>
          </v-row>
          <v-row class="pb-2">
            <v-col cols="6">
              <validation-provider
                v-slot="{ errors }"
                :rules="{ required: true, numeric: true, min_value: 0 }"
                :name="$t('gross')"
              >
                <v-text-field
                  v-model="item.quantity_gross"
                  :label="$t('gross')"
                  type="number"
                  :error-messages="errors"
                  @input="clearError"
                  @focus="keyboardField = 'gross'"
                ></v-text-field>
              </validation-provider>
            </v-col>
            <v-col v-if="keyboardField == 'gross'" cols="6" sm="6" md="4">
              <SimpleKeyboard
                :input="item.quantity_gross"
                :layout="numeric"
                @onChange="
                  (input) => {
                    item.quantity_gross = input;
                  }
                "
              />
            </v-col>
          </v-row>
        </validation-observer>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" text @click="close">{{
          $t('cancel')
        }}</v-btn>
        <v-btn color="blue darken-1" text @click="save">{{ $t('save') }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import SimpleKeyboard from '@/components/keyboard/SimpleKeyboard';
import keyboardMixin from '@/components/keyboard/keyboardMixin';
import numeric from '@/views/operator/components/NumericLayout.js';
export default {
  components: {
    SimpleKeyboard
  },
  props: {
    dialog: {
      type: Boolean,
      default: false
    },
    value: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      item: { ...this.value },
      showDialog: this.dialog,
      numeric: numeric,
      keyboardField: '',
      error: ''
    };
  },
  watch: {
    value: {
      handler(val) {
        this.item = { ...val };
      },
      deep: true
    },
    dialog(val) {
      this.showDialog = val;
      this.error = '';
      console.log('showDialog', this.showDialog);
    }
  },
  methods: {
    activated() {},
    close() {
      this.showDialog = false;
      console.log('close');
      this.$emit('update:dialog', false);
    },
    save() {
      const netF = parseFloat(this.item.quantity_net);
      if (isNaN(netF)) {
        this.error = this.$t('panel.invalid-net-value');
        return;
      }

      const grossF = parseFloat(this.item.quantity_gross);
      if (isNaN(grossF)) {
        this.error = this.$t('panel.invalid-net-value');
        return;
      }

      console.log('netF', netF);
      console.log('grossF', grossF);

      if (grossF < netF) {
        console.log('grossF < netF');
        this.error = this.$t('panel.gross-cant-be-less-than-net');
        return;
      }

      this.$emit('save', this.item);
      this.$emit('update:dialog', false);
    },
    clearError() {
      this.error = '';
    }
  }
};
</script>
