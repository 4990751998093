<template>
  <div>
    <v-row class="mt-10">
      <span class="ml-10 headline">{{ $t('panel.job-log') }}</span>
    </v-row>
    <v-row class="mt-3">
      <v-col cols="12">
        <v-data-table
          :items="log"
          :headers="headers"
          :sort-by="['timeStart']"
          :sort-desc="[true]"
          :footer-props="{
            'items-per-page-options': [10, 20, 30, 40, 50],
            'items-per-page-text': $t('table.per-page')
          }"
          :no-data-text="$t('table.no-data')"
          :options.sync="logOptions"
          :server-items-length="totalLogs"
          dense
        >
          <template #[`item.status`]="{ item }">
            {{ tLogStatus(item.status) }}
          </template>
          <template #[`item.group`]="{ item }">
            {{ tProcessGroupName(item.group_name) }}
          </template>
          <template #[`item.operation`]="{ item }">
            {{ tProcessName(item.group_name, item.operation_name) }}
          </template>
          <template #[`item.actions`]="{ item }">
            <v-icon
              small
              class="mr-2"
              :disabled="!hasPermission('api.change_productionlog')"
              @click="editItem(item)"
            >
              mdi-pencil
            </v-icon>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <JobLogForm
      v-model="editedItem"
      :dialog="editDialog"
      @update:dialog="editDialog = $event"
      @save="handleUpdatedLog"
    />
  </div>
</template>

<script>
import mixin from '@/mixin.js';
import operatorMixin from './operatorMixin.js';
import machineMixin from '@/views/dashboard/components/machines/machineMixin.js';
import JobLogForm from './JobLogForm.vue';

export default {
  components: {
    JobLogForm
  },
  mixins: [mixin, operatorMixin, machineMixin],
  props: {
    log: {
      type: Array,
      default: () => []
    },
    totalLogs: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      logOptions: {
        page: 1,
        itemsPerPage: 10,
        sortBy: ['timeStart'],
        sortDesc: [true]
      },
      editDialog: false,
      editedIndex: -1,
      editedItem: {}
    };
  },
  computed: {
    headers() {
      return [
        { text: 'ID', value: 'id' },
        { text: this.$t('user.user'), value: 'user' },
        { text: this.$t('panel.started-at'), value: 'formattedStartTime' },
        { text: this.$t('panel.stopped-at'), value: 'formattedStopTime' },
        {
          text: this.$t('panel.time-spent'),
          value: 'formattedSpentTime',
          align: 'right'
        },
        { text: this.$t('status'), value: 'status' },
        { text: this.$t('net'), value: 'quantity_net', align: 'right' },
        { text: this.$t('gross'), value: 'quantity_gross', align: 'right' },
        { text: this.$t('panel.operation'), value: 'operation' },
        { text: this.$t('panel.group'), value: 'group' },
        { text: this.$t('comments'), value: 'comments', width: '30%' },
        {
          text: this.$t('actions'),
          value: 'actions',
          sortable: false
        }
      ];
    }
  },
  watch: {
    log: {
      handler(val) {
        console.log('JobLog.watch.log', val);
      },
      deep: true
    }
  },
  methods: {
    editItem(item) {
      this.editedIndex = this.log.indexOf(item);
      this.editedItem = { ...item };
      this.editDialog = true;
      console.log(
        'editedIndex: ' +
          this.editedIndex +
          ' editedItem: ' +
          this.editedItem +
          ' editDialog: ' +
          this.editDialog
      );
    },
    handleUpdatedLog(item) {
      console.log('JobLog.handleUpdatedLog', item);
      this.$emit('update:log', item);
    }
  }
};
</script>

<style lang="scss" scoped></style>
